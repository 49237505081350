// @ts-nocheck

import { Algo, directionToPrettyString, getDirections, IDLE, prettyView, toCanonical, View } from './types.ts';


const HTML4_NAMES_TO_HEX = {
    "aqua": 0x00ffff,
    "black": 0x000000,
    "blue": 0x0000ff,
    "fuchsia": 0xff00ff,
    "green": 0x008000,
    "gray": 0x808080,
    "lime": 0x00ff00,
    "maroon": 0x800000,
    "navy": 0x000080,
    "olive": 0x808000,
    "purple": 0x800080,
    "red": 0xff0000,
    "silver": 0xc0c0c0,
    "teal": 0x008080,
    "white": 0xffffff,
    "yellow": 0xffff00,
}

export default (data : any) : {
    initialConfigurations: string,
    options: string,
    rules: string
} => {
    if(!data.algorithm) throw 'not algorithm found';

    let algoRules = '';
    //console.log(data);
    if(data.algorithm?.alias)
        for(let aliasKey in data.algorithm.alias) {
            algoRules += `@alias ${aliasKey} ${data.algorithm.alias[aliasKey]}\n\n`;
        }
    let visibilityRange = 1;
    for(let i = 0; i < data.algorithm.rules.length; i+=2) {
        let r = data.algorithm.rules[i];
        let d = data.algorithm.rules[i+1];
        
        r = r.trim().split(' ').filter(s => s.length > 0);
        visibilityRange = Math.floor((r[Math.floor(r.length/2)].trim().length - 1)/2);

        r[Math.floor(r.length/2)] += ' -> '+d.replace(/\(|\)/g, '').replace('up','front').replace('down', 'back');
        for(let i = 0; i < Math.floor(r.length/2); i++) {
            r[i] = r[i].replaceAll('|', '.').replaceAll('+','.');
            r[r.length-i-1] = r[r.length-i-1].replaceAll('|', '.').replaceAll('+','.');
            
            if(r[i].indexOf('-') != -1) for(let j = 0; j < i; j++) r[j] = '';
            if(r[r.length-i-1].indexOf('-') != -1) for(let j = 0; j < i; j++) r[r.length-j-1] = '';
        }
        algoRules += r.join('\n').replace('"','')+'\n\n';
    }
    

    let colors = 'colors:\n';
    for(let [colorKey, colorValue] of Object.entries(data.graphics.colors as Record<string,string>)) {
        if(isNaN(parseInt(colorValue))) 
            colorValue = HTML4_NAMES_TO_HEX[colorValue] || 0x555555;
        colors += `  ${colorKey}: ${colorValue}\n`;
    }
    
    const algoOptions = `
walls: [[0,0,0],[6,6,2]]
chirality: ${!!data.model.chirality}
visibilityRange: ${visibilityRange}
dimension: 2
topology: ${Object.values(data.algorithm.initial_configuration)[0].tore ? 'torus' : 'finite-grid'}
${colors}
`;
    let config_text = ''
    let configurationDict = data.algorithm.initial_configuration;
    if(Array.isArray(configurationDict)) {
        configurationDict = {default: configurationDict}
    }
    type ConfigList = Record<string, (
        {
            tore: {
                width: number,
                height: number,
                positions: Array<Array<string>>
            }
        }
        | Record<string, Array<Array<string>>>
    )>;

    const defaultGridSize = data.algorithm.grid_size || 10;
    for(let [name, configuration] of Object.entries(configurationDict as ConfigList))
    {
        //defaut:[6,6,5],0
        let height = configuration.height || defaultGridSize;
        let width = configuration.width || defaultGridSize;
        let seed = configuration.seed || 0;
        let topologyType = 'infinite-grid';
        let offx = 0;
        let offy = 0;
        if(data.algorithm.offset) {
            offx = data.algorithm.offset[0];
            offy = data.algorithm.offset[1];
        }
        if(configuration.tore) {
            let toreOption = configuration.tore;
            height = parseInt(configuration.tore.height);
            width = parseInt(configuration.tore.width);
            seed = configuration.tore.seed || 0;
            configuration = configuration.tore.positions;
            topologyType = 'torus'
        }

        if(configuration[0].length > 0 && configuration[0].reduce((acc, c) => acc && c=='W', true)) 
        {
            offx = 0;
            offy = 0;
            height = configuration[0].length-1;
            width = configuration.length-1;
            //topologyType = 'finite-grid'
        }

        config_text += `${name}:${topologyType}[${height+1},${width+1},1],${seed}\n`;
        for(let i = 0; i < offy; i++) {
            config_text += '.\n';
        }
        config_text += configuration.map(l => (
            Array.from(Array(offx).keys()).map(i => '.').join('')+l.join('')
        )).join('\n');
        config_text += '\n\n';
    }

    return {
        initialConfigurations: config_text,
        rules: algoRules,
        options: algoOptions,
    }
}
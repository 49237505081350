

// horizontal direction
(function resizableX() {
    const resizer = document.querySelector(".resizer-x");
    resizer.addEventListener("mousedown", onmousedown);
    
    function onmousedown(e) {
        e.preventDefault();
        document.addEventListener("mousemove", onmousemove);
        document.addEventListener("mouseup", onmouseup);
    }
    function onmousemove(e) {
        e.preventDefault();
        const clientX = e.clientX;
        const deltaX = clientX - (resizer._clientX || clientX);
        resizer._clientX = clientX;
        const l = resizer.previousElementSibling;
        const r = resizer.nextElementSibling;
        // LEFT
        if (deltaX < 0) {
            const w = Math.round(parseInt(getComputedStyle(l).width) + deltaX);
            l.style.flex = `0 ${w < 10 ? 0 : w}px`;
            r.style.flex = "1 0";
        }
        // RIGHT
        if (deltaX > 0) {
            const w = Math.round(parseInt(getComputedStyle(r).width) - deltaX);
            r.style.flex = `0 ${w < 10 ? 0 : w}px`;
            l.style.flex = "1 0";
        }
        window.dispatchEvent(new Event('resize'));
    }
    function onmouseup(e) {
        e.preventDefault();
        document.removeEventListener("mousemove", onmousemove);
        document.removeEventListener("mouseup", onmouseup);
        delete e._clientX;
    }
})();

// vertical direction
(function resizableY() {
    const resizer = document.querySelector(".resizer-y");
    resizer.addEventListener("mousedown", onmousedown);
    
    function onmousedown(e) {
    e.preventDefault();
    document.addEventListener("mousemove", onmousemove);
    document.addEventListener("mouseup", onmouseup);
    }
    function onmousemove(e) {
        e.preventDefault();
        const clientY = e.clientY;
        const deltaY = clientY - (resizer._clientY || clientY);
        resizer._clientY = clientY;
        const t = resizer.previousElementSibling;
        const b = resizer.nextElementSibling;
        // FRONT
        if (deltaY < 0) {
            const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
            t.style.flex = `0 ${h < 10 ? 0 : h}px`;
            b.style.flex = "1 0";
        }
        // DOWN
        if (deltaY > 0) {
            const h = Math.round(parseInt(getComputedStyle(b).height) - deltaY);
            b.style.flex = `0 ${h < 10 ? 0 : h}px`;
            t.style.flex = "1 0";
        }
        window.dispatchEvent(new Event('resize'));
    }
    function onmouseup(e) {
        e.preventDefault();
        document.removeEventListener("mousemove", onmousemove);
        document.removeEventListener("mouseup", onmouseup);
        delete e._clientY;
    }
})();
import { errorRuleOverlap, testRule } from "./symmetries.ts";
import {  
  IDLE, 
  FRONT,
  LEFT,
  BACK,
  RIGHT,
  ABOVE,
  BELOW,
  View, 
  RulesReference,
  Rule,
  Rules,
  Alias,
  getDirections,
  WallBoundaries,
RuleOptions} from "./types.ts";


// number of state in a 2 dimensional view, depeding on the visibility range
// https://oeis.org/A001844
const ruleLineLength = [
  1, 5, 13, 25, 41, 61, 85, 113, 145, 181,
]

// odd numbers
// https://oeis.org/A005408  :)
const mainRuleLineLength = [
	1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 
]

const fill = (l:number, s = '.') => {
  let r = [];
  for(let i = 0; i < l; i++){
    r.push(s);
  }
  return r.join('');
}

function parseRuleOptions(lines: string[]) : RuleOptions {
  const dict : Map<string,string> = new Map();
  for(let line of lines) {
    let m = line.match(/^@([a-zA-Z0-9_]+)[ \t]*(.*)$/);
    if(!m) throw `invalid option ${line}`;
    dict.set(m[1], m[2]);
  }

  return {
    maxWalls: parseInt(dict.get('maxWalls') || '0'),
    override: dict.has('override'),
  }
}

export function parseRule(r:string[], line:number, visibilityRange: number, dimension: number) {
  r = r.map(el => el.trim());
  let m = null;

  const options = parseRuleOptions(r.filter(el => el.match(/^@/)));
  r = r.filter(el => !el.match(/^@/));

  // Find the destination, which corresponds to the "middle" of the rule
  let middleIdx: number|null = null;
  for(let i=0; i < r.length; i++) {
    if(m = r[i].match(/^(.*)\->(.*)$/)) {
      if(middleIdx === null) {
        middleIdx = i;
      } else {
        throw `rule at line ${line} contains two destination declarations (${line + middleIdx} and ${line + i})`;
      }
    }
  }
  if(middleIdx === null) throw `rule at line ${line} does not contain a destination declaration`;

  m = r[middleIdx].match(/^(.*)\->(.*)$/);
  if(!m) throw `rule at line ${line} does not contain a destination declaration`;

  let dest_text = m[2].trim();
  r[middleIdx] = m[1].trim();


  const wallX: WallBoundaries = [0,0];
  const wallY: WallBoundaries = [0,0];
  const wallZ: WallBoundaries = [0,0];
  
  if(2*visibilityRange + 1  !== r[middleIdx].length)
  {
    console.log({visibilityRange}, r[middleIdx]);
    console.log(r);
    throw `line ${line+middleIdx} should contain ${2*visibilityRange + 1} states (found ${r[middleIdx].length} instead: "${r[middleIdx]}")` 
  }

  for(let i = 1; i <= visibilityRange; i++) {
    //console.log(middleIdx, i, r[middleIdx][visibilityRange - i], r[middleIdx][visibilityRange + i], r[middleIdx+i], r[middleIdx-i])
    if(!wallX[0] && r[middleIdx][visibilityRange - i] == '|') {
      wallX[0] = -i;
    }
    if(!wallX[1] && r[middleIdx][visibilityRange + i] == '|') {
      wallX[1] = i;
    }
    if(!wallY[0] && r[middleIdx+i].indexOf('-') != -1) {
      wallY[0] = -i;
      r[middleIdx - wallY[0]] += fill(2*(visibilityRange - i) + 1 - r[middleIdx - wallY[0]].length)
    } else if(wallY[0]) {
      r[middleIdx - wallY[0]] += fill(2*(visibilityRange - i) + 1)
    } else {
      if(2*(visibilityRange - i) + 1  !== r[middleIdx+i].length)
      {
        throw `line ${line+middleIdx+i} should contain ${2*(visibilityRange - i) + 1} states (found ${r[middleIdx+i].length} instead)` 
      }
    }
    if(!wallY[1] && r[middleIdx-i].indexOf('-') != -1) {
      wallY[1] = i;
      r[middleIdx - wallY[1]] += fill(2*(visibilityRange - i) + 1 - r[middleIdx - wallY[1]].length)
    } else if(wallY[1]) {
      r[middleIdx - wallY[1]] += fill(2*(visibilityRange - i) + 1)
    } 
    else 
    {
      if(2*(visibilityRange - i) + 1  !== r[middleIdx-i].length)
      {
        throw `line  ${line+middleIdx-i} should contain ${2*(visibilityRange - i) + 1} states (found ${r[middleIdx-i].length} instead)` 
      }
    }
  }

  let zboundaries = [middleIdx + 1 + (-wallY[0] || visibilityRange), middleIdx - 1 - (wallY[1] || visibilityRange)];

  // we compute the zboundaries (and the z view) only in 3 dimensions
  if(dimension === 3) {
    if(r[zboundaries[0]] === undefined) throw `There should be at least one more line containing states after line ${line + zboundaries[0]-1}`
    if(r[zboundaries[1]] === undefined) throw `There should be at least one more line containing states before line ${line + zboundaries[1]+1}`

    //console.log({wallX, wallY}, zboundaries[0], zboundaries[1]);
    for(let i = 1; i <= visibilityRange; i++) {
      if(!wallZ[0])
      { 
        if(r[zboundaries[0]] == undefined) {
          throw `There should be at least one more line containing states at the end of the rule starting line ${line + 1}`
        }
        if(r[zboundaries[0]].indexOf('-') != -1) {
          wallZ[0] = -i;
          zboundaries[0]++;
        }
      }
      else {
        if(r[zboundaries[0]] != undefined) {
          throw `There should be no more line containing states after line ${line + zboundaries[0] + 1}`
        }
      }
      if(!wallZ[1])
      {
        if(r[zboundaries[1]] == undefined) {
          throw `There should be at least one more line containing states before line ${line}`
        }
        if(r[zboundaries[1]].indexOf('-') != -1) {
          wallZ[1] = i;
          zboundaries[1]--;
        }
      }
      else {
        if(r[zboundaries[1]] != undefined) {
          throw `There should be no more line containing states before line ${line + zboundaries[1] + 1}`
        }
      }
      if(!wallZ[1] && i != visibilityRange) {
        zboundaries[1] -= (visibilityRange-i)*2+1;
      }
      if(!wallZ[0] && i != visibilityRange) {
        zboundaries[0] += (visibilityRange-i)*2+1;
      }
    }
    if(r[zboundaries[0]+1] != undefined) {
      throw `There should be no more line containing states after line ${line + zboundaries[0]}`
    }
    if(r[zboundaries[1]-1] != undefined) {
      throw `There should be no one more line containing states before line ${line + zboundaries[1]}`
    }

    if(wallZ[1] > 0 && wallZ[1] < visibilityRange) {
      r[0] += fill(ruleLineLength[visibilityRange - wallZ[1]]);
    }
    if(wallZ[0] < 0 && wallZ[0] > -visibilityRange) {
      r[r.length - 1] += fill(ruleLineLength[visibilityRange + wallZ[0]]);
    }
  } else if(dimension === 2) {



    if(r[zboundaries[0]] != undefined) {
      throw `There should be no more line containing states after line ${line + zboundaries[0]-1}`
    }
    if(r[zboundaries[1]] != undefined) {
      throw `There should be no one more line containing states before line ${line + zboundaries[1]+1}`
    }

    let s = '.'; //'W';
    for(let i = 1; i <= visibilityRange; i++) {
      r[0] = fill(ruleLineLength[visibilityRange - i], s) + r[0];
      r[r.length - 1] += fill(ruleLineLength[visibilityRange - i], s);
      s = '.';
    }


  } else 
  {
    throw `Dimension is not defined in the options`; 
  }
  //console.log(r);
  let rStr = r.join('');
  rStr = rStr.replaceAll('-', '.').replaceAll('|', '.');

  const view = new View();
  let v_r = 0;
  for(let {x,y,z} of getDirections(visibilityRange))
  {
    view.set({x,y,z}, rStr[v_r++]);
  }
  view.setWalls({wallX, wallY, wallZ})

  let color = view.getCenter();
  dest_text = dest_text.replaceAll(' ', '');
  m = dest_text.match(/\[([^\]]*)\],?([^\[,]*)/)
  let destArray: string[] = [];
  if(m) {
    destArray = m[1].split(',');
    color = m[2] || view.getCenter();
  } 
  else 
  {
    m = dest_text.match(/([^,]*),?([^,]*)/)
    if(!m) throw `invalid destination ${dest_text}`;

    destArray = [m[1]];
    color = m[2] || view.getCenter();
  }



  const dir = destArray.map(d => {
    d = d.trim().toUpperCase();
    switch(d)
    {
      case 'IDLE': return IDLE;
      case 'FRONT': return FRONT;
      case 'LEFT': return LEFT;
      case 'BACK': return BACK;
      case 'RIGHT': return RIGHT;
      case 'ABOVE': return ABOVE;
      case 'BELOW': return BELOW;
      default: throw "unknown direction "+d;
    }
  })
  return {view, destination: {dir, color}, options};
}


export default function(text:string, visibilityRange:number, dimension: number): {rules:Rules, rulesReference: RulesReference, alias: Alias} {

  let rules: Rules = {}
  let rulesReference: RulesReference = {}
  let alias: Alias = {};

  let currentRawRule = [];
  
  let lines = text.split('\n');
  let rule_starting_ln = 0;
  for(let ln = 0; ln < lines.length; ln++) {
    let line = lines[ln];
    if(line.match(/^ *#/)) continue;
    let m;
    if(m = line.trim().match(/^alias: (.) \{(.*)\}$/)) {
      alias[m[1]] = m[2].split(',');
      continue;
    }
    if(m = line.trim().match(/^@alias +(.) +\{(.*)\}$/)) {
      alias[m[1]] = m[2].split(',');
      continue;
    }
    if(line.trim() === '' && currentRawRule.length > 0) {
      const rule = parseRule(currentRawRule, rule_starting_ln,  visibilityRange, dimension);
      const viewKey = rule.view.toString();
      if(rules[viewKey])
      {
        throw `
    You cannot define two rules with exactly the same view:
    at line ${rulesReference[viewKey].line}
and at line ${rule_starting_ln}
        `;
      }
      rules[viewKey] = rule;
      rulesReference[viewKey] = {
        line: rule_starting_ln, 
        line_end: ln, 
        view: rule.view, 
        transformations:[]
      };

      currentRawRule = [];
      continue;
    }
    
    if(currentRawRule.length === 0) rule_starting_ln = ln + 1;

    if(line.trim() === '')
      continue;

    currentRawRule.push(line)
  }


  return {rules, rulesReference, alias};
}



export function getAlgoInfoFromString(content : string) {

  if(content.toString().indexOf('****** OPTIONS ******') === -1) throw 'not a web-algo file';

  const rawOptions: string[] = [];
  const rawRules: string[] = [];
  const rawInitialConfig: string[] = [];
  let state : 'OPTIONS' | 'INITIAL_CONFIG' | 'RULES' | null = null;
  content.toString().split('\n').forEach(line => {
      if(line.trim() === '****** OPTIONS ******') {
          state = 'OPTIONS';
          return;
      }
      if(line.trim() === '****** INITIAL CONFIGURATIONS ******') {
          state = 'INITIAL_CONFIG';
          return;
      }
      if(line.trim() === '****** RULES ******') {
          state = 'RULES';
          return;
      }
      switch(state) {
          case 'INITIAL_CONFIG': rawInitialConfig.push(line); break;
          case 'RULES': rawRules.push(line); break;
          case 'OPTIONS': rawOptions.push(line); break;
      }
  })
  const optionsText = rawOptions.join('\n');
  const rulesText = rawRules.join('\n');
  const initialConfigText = rawInitialConfig.join('\n');

  return [optionsText, rulesText, initialConfigText]

}
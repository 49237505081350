
export type Paper = {
    type: Array<string>,
    algos: Array<{type: Array<string>}>,
}
export type TypeDefinition = 
      Array<string> 
    | 'int'
    | {order: boolean, values: Array<string>};

export type Summary = {
    types: Record<string,TypeDefinition>,
    papers: Record<string,Paper>,
    default: Array<string>,
};

export type Settings = {
    synchrony: string,
    dimension: number,
    topology: string,
    chirality: string,
    range: number,
    robots: number,
    colors: number,
    opacity: string,
    'colors-mode': string,
}


export function settings_to_path(settings: Settings) {
    return 'algo/'+
    `${settings.synchrony}/`+
    `${settings.dimension}/`+
    `${settings.topology}/`+
    `${settings.chirality}/`+
    `range-${settings.range}/`+
    `${settings.robots}-robots-${settings.colors}-colors`+
    `${settings.opacity == 'opaque' 
        ? '' 
        : '-'+settings.opacity}`+
    `${settings['colors-mode'] == 'modifiable-colors' 
        ? '' 
        : '-'+settings['colors-mode']}`
    
}

function parse_type_value(data: Summary, raw_v :string){
    const types_list = Object.keys(data.types);
    if(raw_v.indexOf('-') !== -1 &&
      types_list.includes(raw_v.split('-')[0]))
    {
      let [t, v] = raw_v.split('-');
      let parsed_v = parseInt(v);
      if(!isNaN(parsed_v)) return [t, parsed_v];
      if(v == '*') {
        return data.types[t] === 'int'
         ? [t, Number.POSITIVE_INFINITY]
         : [t, v];
      }
      return [t, v];
    } 
    else 
    {
      for(let [key, value] of Object.entries(data.types))
      {
        if(value == 'int') throw new Error('int type should be used with a range');
        if(Array.isArray(value.values))
        {
          if(value.values.includes(raw_v)) {
            return [key, raw_v];
          }
        }
        else if (Array.isArray(value) && value.includes(raw_v)) 
        {
          return [key, raw_v];
        }
      };
    }
    throw new Error('could not parse type value: ' + raw_v);
}
export function create_settings(data: Summary, type_list: Array<string>) : Settings {
    const types = [...data.default, ...type_list];
    const settings : Record<string, number|string> = {};
    types.forEach((type) => {
        const [t, v] = parse_type_value(data, type);
        settings[t] = v;
    });
    return settings as Settings;
}

export function algo_model_to_path(data: Summary, type_list: Array<string>) {
    return settings_to_path(create_settings(data, type_list));
}

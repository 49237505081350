
import { Algo, directionToPrettyString, getDirections, IDLE, prettyView, toCanonical, View } from './types.ts';


const HTML4_NAMES_TO_HEX = {
    "aqua": 0x00ffff,
    "black": 0x000000,
    "blue": 0x0000ff,
    "fuchsia": 0xff00ff,
    "green": 0x008000,
    "gray": 0x808080,
    "lime": 0x00ff00,
    "maroon": 0x800000,
    "navy": 0x000080,
    "olive": 0x808000,
    "purple": 0x800080,
    "red": 0xff0000,
    "silver": 0xc0c0c0,
    "teal": 0x008080,
    "white": 0xffffff,
    "yellow": 0xffff00,
}

export default (content : string) : {
    initialConfigurations: string,
    options: string,
    rules: string
} => {
    if(content.toString().indexOf('****** OPTIONS ******') === -1) throw 'not a web-algo file';

    const rawOptions : string[] = [];
    const rawRules : string[] = [];
    const rawInitialConfig : string[] = [];
    let state : 'OPTIONS' | 'INITIAL_CONFIG' | 'RULES' | null = null;
    content.toString().split('\n').forEach(line => {
        if(line.trim() === '****** OPTIONS ******') {
            state = 'OPTIONS';
            return;
        }
        if(line.trim() === '****** INITIAL CONFIGURATIONS ******') {
            state = 'INITIAL_CONFIG';
            return;
        }
        if(line.trim() === '****** RULES ******') {
            state = 'RULES';
            return;
        }
        switch(state) {
            case 'INITIAL_CONFIG': rawInitialConfig.push(line); break;
            case 'RULES': rawRules.push(line); break;
            case 'OPTIONS': rawOptions.push(line); break;
        }
    })
    return {
        initialConfigurations: rawInitialConfig.join('\n'),
        rules: rawRules.join('\n'),
        options: rawOptions.join('\n'),
    }
}
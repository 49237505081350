
import { Position } from './position.ts';
import { Direction , getDirections, View, Robot } from './types.ts';

type Topology = {
    type: 'torus' | 'infinite-grid' |'finite-grid',
    x: number,
    y: number,
    z: number,
    dimension: number
  }
  // The dimension of the third dimension is called depth

export class Config
{
  #grid: { [pos:string]: Robot} = {}
  seed: number = Math.random();
  topology: Topology;
  constructor(topology : Topology) {
    this.topology = topology;
  }
  canonicalPosition(pos:Position) {
    if(this.topology.type === 'torus') {
      return new Position(
        (pos.x + this.topology.x) % this.topology.x,
        (pos.y + this.topology.y) % this.topology.y,
        (pos.z + this.topology.z) % this.topology.z,
      );
    }
    return pos;
  }
  get(pos:Position) {
    if(this.topology.type === 'finite-grid') {
      if(pos.x < -1 || pos.y < -1 || pos.z < -1) 
        return '.';
      if(pos.x > this.topology.x || pos.y > this.topology.y || pos.z > this.topology.z) 
        return '.';
      if(this.topology.dimension === 2) {
        if(pos.z != 0) return '.';
        if(pos.x == -1 || pos.y == -1 && pos.z == 0) return 'W';
        if(pos.x == this.topology.x || pos.y == this.topology.y && pos.z == 0) return 'W';
      } else {
        if(pos.x == -1 || pos.y == -1 || pos.z == -1) return 'W';
        if(pos.x == this.topology.x || pos.y == this.topology.y || pos.z == this.topology.z) return 'W';
      }
      
    } else if(this.topology.type === 'torus') {
      if(pos.z <= -1 || pos.z >= this.topology.z) 
        return '.';
    }
    pos = this.canonicalPosition(pos);
    return this.#grid[pos.toString()]?.color || '.';
  }
  set(pos:Position, color:string) {
    pos = this.canonicalPosition(pos);
    this.#grid[pos.toString()] = {
      pos,
      color
    }
  }
  robots() : Robot[] {
    return Object.keys(this.#grid).map(k => this.#grid[k]);
  }
  copy() {
    const g = new Config(this.topology);
    this.robots().forEach(({pos, color}) => g.set(pos,color));
    g.seed = this.seed;
    
    return g
  }

  equals(conf : Config) {
    const thisRobots = this.robots();
    const ok = thisRobots.reduce((ok,r) => ok && conf.get(r.pos) === r.color, true);
    return ok && thisRobots.length === conf.robots().length;
  }

  getView(pos: Position, visibilityRange: number, dimension:number) : View {
    const dirs = getDirections(visibilityRange);
    const view = new View();
    for(const dir of dirs) {
      view.set(dir, this.get(pos.to(dir)))
    }
    return view;
  }
  asKey() {
    return this.robots().map(r => r.pos.toString()+':'+r.color).sort().join('\n');
  }
  toString() {
    return 'config:\n'+this.robots().map(r => r.pos.toString()+':'+r.color).join('\n');
  }
};
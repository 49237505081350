

import { 
  Direction, 
  Options, 
  Rules,
  Algo, 
  Rule, Rules,
  ruleToString,
  RuleReference, RulesReference, 
  prettyView} from './src/types';
import { Position } from './src/position';
import { Config } from './src/config';
import {
  errorRuleOverlap,
  rotateX,
  rotateY,
  rotateZ,
  testRule,
  transformRules
} from './src/symmetries';
import rulesParser from './src/rulesParser';
import configParser from './src/configParser'
import YAML from 'yaml';

import './src/gui_resizable_div';
import {registerEventListeners, initDebugPanel, initLogPanel, getRulesText, getInitialText, getOptionsText} from './src/gui_editors_panel';

import  * as Scene from './src/gui_scene'
import { onError } from './src/error_panel';
import { sceneOptions } from './src/gui_scene';
import { algoStep } from "./src/types.ts";

const initialConfigSelector = document.getElementById('initial-configurations-selector');


let defaultOptions : Options = {
  walls: [[0,0,0], [6,6,6]],
  colors: {
    'F': 0xff0000,
    'L': 0x00ff00,
    'A': 0x0000ff,
    'B': 0x00004f
  },
  visibilityRange: 1,
  is3d: true,
  chirality: true,
  cacheRules: false,
  seed:0,
  dimension: 3
}

let algo : Algo = {
  rules: {},
  rulesReference: {},
  options: {...defaultOptions},
  usedViews: new Set(),
}

const container = document.getElementById("app");
Scene.create(container, algo);

initDebugPanel(algo);
initLogPanel(algo);
onError('Welcome to the 3D robot simulator. Edit the rules to trigger compilation')


/*
const symmetryWorker = new Worker(
  './src/symmetries.js',
  {type: 'module'}
);

symmetryWorker.onmessage = function(e) {
  console.log('received data from worler', e)
  algo = e.data;
  let round = 0;
  while(prevStep()) round++;
  while(round--) nextStep();

}
*/

let initialConfigurations = {}

let _lastInitialConfigurationValue : string | null = '';
function onInitialConfigurationChange(v : string, {reload}: {reload: boolean} = {reload: false}) {
  if(reload) {
    v = _lastInitialConfigurationValue;
  } else if(_lastInitialConfigurationValue === v) {
    return;
  }
  _lastInitialConfigurationValue = v;

  try{
    initialConfigurations = configParser(v, algo.options);
  } catch(e) { 
    onError(e.message);
    return;
  }
  
  updateInitialConfigurationsList(Object.keys(initialConfigurations))
}

let _lastOptionsValue : string | null = '';
function onOptionsChange(v) {
  if(_lastOptionsValue === v) {
    return;
  }
  _lastOptionsValue = v;
  console.log('Options Changed', v);

  jumpPrevStep(0);

  algo.options = {
    ...defaultOptions, 
    ...YAML.parse(v)
  };
  algo.options.colors = {
    ...defaultOptions.colors,
    ...algo.options.colors
  }

  if(isNaN(algo.options.visibilityRange) || algo.options.visibilityRange <= 0) {
    throw new Error('visibilityRange is not a valid number');
  }
  onInitialConfigurationChange('', {reload: true});
  //Scene.init(currentConfig, algo);
  //currentConfig.seed = algo.options.seed;
}

let moveToSameRoundTimoutId = null;
function moveToSameRound(round: number) {
  if(moveToSameRoundTimoutId) {
    clearTimeout(moveToSameRoundTimoutId);
  }
  moveToSameRoundTimoutId = setTimeout(() => {
    moveToSameRoundTimoutId = null;
    //perform at most 10 steps
    let i = 10;
    while(nextStep(false) && currentRound < round && i-->0);

    Scene.updateRobotMesh(currentConfig, algo);
    setCanvasRoundInfo(currentRound);

    if(i === -1 && currentRound < round) {
      moveToSameRound(round);
    }
  }, 0);
}

let _lastRulesValue : string | null = '';
let _lastRulesParsed : string | null = '';

function onRulesChange(v) {
  if(_lastRulesValue === v) {
    return;
  }
  _lastRulesValue = v;

  try {
    //console.log('parsing with options', JSON.parse(JSON.stringify(algo.options)));
    const {rules, rulesReference, alias} = rulesParser(v+'\n\n', algo.options.visibilityRange, algo.options.dimension);

    //_lastRulesParsed
    //const newRulesParsed = Object.values(rules).map(r => ruleToString(r)).join('\n'); // JSON.stringify(rules);
    //if(_lastRulesParsed == newRulesParsed) {
    //  return;
    //}
    //_lastRulesParsed = newRulesParsed;

    Scene.init(currentConfig, algo);

    algo.alias = alias;
    algo.rules = rules;
    algo.rulesReference = rulesReference;

  
    //console.log(JSON.parse(JSON.stringify(algo.rules)));
    transformRules(algo);
    //console.log(JSON.parse(JSON.stringify(algo.rules)));
    console.log('New Algo', algo);
    let round = currentRound;
    //while(prevStep()) round++;
    //while(round--) nextStep();

    selectInitialConfiguration(currentConfigName);
    Scene.updateRobotMesh(currentConfig, algo);

    moveToSameRound(round);

    //symmetryWorker.postMessage(algo);
    //console.log(JSON.parse(JSON.stringify(rules)));
    //console.log(Object.keys(rules).length);

  } catch(e) {
    _lastRulesValue = '';
    onError(e)
    console.log(e);
    return;
  }
  onError(null);
}

registerEventListeners(onRulesChange, onOptionsChange, onInitialConfigurationChange);

function selectInitialConfiguration(name) {
  onError(null);

  jumpPrevStep(0);
  currentConfigName = name;
  currentConfig = initialConfigurations[name].config;
  algo.options.walls[1] = initialConfigurations[name].gridSize.slice();
  algo.usedViews = new Set();

  currentConfig.seed = initialConfigurations[name].seed;
  console.log('new Config wiht seed', algo.options.seed);
  Scene.init(currentConfig, algo);
  nextStep();
  prevStep();
}

initialConfigSelector.addEventListener('change', (e) => {
  console.log('selecting', e.target.value);
  selectInitialConfiguration(e.target.value)
})



let currentConfig;
let currentConfigName = 'default';
let currentRound = 0;


const configList : Config[] = []

// go back to step 0
function jumpPrevStep(round: number) {
  if(round >= currentRound) return;

  while(currentRound > round) {
    if(configList.length === 0) {
      break;
    }
    currentConfig = configList.pop();
    currentRound--;
  }
  Scene.updateRobotMesh(currentConfig, algo);
  setCanvasRoundInfo(currentRound);
  return true;
}

function nextStep(screenUpdate: boolean = true) : Boolean {
  const nextConfig = algoStep(algo, currentConfig);
  if(nextConfig.equals(currentConfig)) {
    return false;
  }
  configList.push(currentConfig);
  currentConfig = nextConfig;
  currentRound++;
  if(screenUpdate)
  {
    Scene.updateRobotMesh(currentConfig, algo);
    setCanvasRoundInfo(currentRound);
  }
  return true;
}
function prevStep() : Boolean {
  if(!configList.length)
  {
    return false;
  }
  currentConfig = configList.pop();
  Scene.updateRobotMesh(currentConfig, algo);
  currentRound--;
  setCanvasRoundInfo(currentRound);
  return true;
}



let keyPressed: null | 'ArrowRight' | 'ArrowLeft'  = null;
let keyPressedTimeoutID = null;
let keyPressedStepSpeed = 200;

function keyPressedLoop() {
  keyPressedStepSpeed = Math.max(keyPressedStepSpeed - 10, 1);
  if(keyPressed === 'ArrowRight') {
    nextStep();
  } else if (keyPressed === 'ArrowLeft'){
    prevStep();
  } else {
    return;
  }
  keyPressedTimeoutID = setTimeout(keyPressedLoop, keyPressedStepSpeed);
}

document.addEventListener('keydown', (e) => {
  if(e.key === 'n' || e.key === 'ArrowRight') {
    keyPressed = 'ArrowRight';
    keyPressedStepSpeed = 200;
    keyPressedLoop();
  } else if(e.key === 'b' || e.key === 'ArrowLeft') {
    keyPressed = 'ArrowLeft';
    keyPressedStepSpeed = 200;
    keyPressedLoop();
  }
})

document.addEventListener('keyup', (e) => {
  keyPressed = null;
  keyPressedTimeoutID && clearTimeout(keyPressedTimeoutID);
})


/*
if(localStorage.getItem('options')) {
  onOptionsChange(localStorage.getItem('options'));
}
if(localStorage.getItem('initial')) {
  onInitialConfigurationChange(localStorage.getItem('initial'));
}
if(localStorage.getItem('rules')) {
  //onRulesChange(localStorage.getItem('rules'));
}
*/


let canvasInfo = document.createElement('div');
canvasInfo.style.position = 'absolute';
//canvasInfo.style.zIndex = 1;    // if you still don't see the label, try uncommenting this
canvasInfo.style.width = 100;
canvasInfo.style.height = 100;
canvasInfo.style.backgroundColor = "rgba(0,0,0,0.1)";
canvasInfo.style.top = 0 + 'px';
canvasInfo.style.left = 250 + 'px';
document.body.appendChild(canvasInfo);

setTimeout(() => {
  setCanvansInfo("You are out of focus, please click on the canvas.");
document.getElementById('three-container').addEventListener('click', (e) => {
  setCanvansInfo("use left or right arrow to execute a round");
})
document.addEventListener('click', () => {
  setCanvansInfo("You are out of focus, please click on the canvas.");
})
}, 1000);

function setCanvansInfo(text) {
  canvasInfo.innerHTML = text;
}


let canvasControl = document.createElement('div');
canvasControl.style.position = 'absolute';
//canvasInfo.style.zIndex = 1;    // if you still don't see the label, try uncommenting this
canvasControl.style.width = 400;
canvasControl.style.height = 400;
canvasControl.style.backgroundColor = "rgba(0,0,0,0.1)";
canvasControl.style.top = 0 + 'px';
canvasControl.style.left = 600 + 'px';
canvasControl.innerHTML = `
<div id="canvas-control">
<label for="print-marker-option">Print un-visited marker</label>
<input type="checkbox" name="print-marker-option" id="print-marker-option">
<div>
`

document.body.appendChild(canvasControl);

document.getElementById('print-marker-option').addEventListener('click', (e) => {
  sceneOptions.printMarker = e.target.checked;
  Scene.init(currentConfig, algo);
})


let canvasRoundInfo = document.createElement('div');
canvasRoundInfo.style.position = 'absolute';
//canvasInfo.style.zIndex = 1;    // if you still don't see the label, try uncommenting this
canvasRoundInfo.style.width = 100;
canvasRoundInfo.style.height = 100;
canvasRoundInfo.style.backgroundColor = "rgba(0,0,0,0.1)";
canvasRoundInfo.style.bottom = 0 + 'px';
canvasRoundInfo.style.left = 150 + 'px';
canvasRoundInfo.id = "canvas-round-info";
document.body.appendChild(canvasRoundInfo);
canvasRoundInfo.addEventListener('click', () => {
  jumpPrevStep(0);
})

function setCanvasRoundInfo(r) {
  canvasRoundInfo.innerHTML = `Round ${r} (click here to go back to round 0) `;
}
setCanvasRoundInfo(0);

function updateInitialConfigurationsList(list) {
  let oldValue = initialConfigSelector.value || 'default';
  while(initialConfigSelector.length)
  {
    initialConfigSelector.remove(0);
  }
  let found = false;
  list.forEach(el => {
    const opt = document.createElement("option");
    opt.value = el;
    opt.text = el;
    if(el === oldValue) {
      opt.selected = true;
      found = true;
    }
    initialConfigSelector.add(opt)
  })
  if(found)
  {
    selectInitialConfiguration(oldValue)
  } else {
    selectInitialConfiguration(list[0])
    //onError('the initial configuration disapeared.\nThe first one is now selected');
  }
}


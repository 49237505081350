import {Direction} from './types.ts';
  

export class Position
{
    x=0;
    y=0;
    z=0;
  
    constructor(_x: number, _y: number, _z:number) {
      this.x = _x;
      this.y = _y;
      this.z = _z;
    }
    toString() {
      return this.x+','+this.y+','+this.z;
    }
  
    to(dir: Direction) {
      return new Position(this.x + dir.x, this.y+dir.y, this.z+dir.z);
    }
}


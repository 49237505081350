
types: 
  synchrony: ['async', 'ssync', 'fsync']
  dimension: ['42d', '3d', '2d']
  topology: 
    values: ['infinite-grid', 'torus', 'finite-grid']
    order: false
  chirality: ['without-chirality', 'with-chirality']
  opacity: ['opaque', 'transparent']
  colors-mode: ['fixed-colors', 'modifiable-colors']
  range: int
  robots: int
  colors: int
  
default: ['fsync', '2d', 'opaque', 'modifiable-colors']

papers:
  TCS2023:
    pdf: https://www.sciencedirect.com/science/article/pii/S0304397523004565?casa_token=XE46cqRFt6YAAAAA:cPmu5xbQSD_jlM7UEbuEXYlbuZX4Eh8LZx6okwToB4ZUKZx8KqHRg0vZBzEitelT9x1qtcD4gYsD
    type: ['2d', 'torus', 'opaque', 'modifiable-colors']
    algos:
      - type:  ['with-chirality', 'range-1', 'robots-3', 'colors-2']
      - type:  ['without-chirality', 'range-1', 'robots-3', 'colors-3']
      - type:  ['with-chirality', 'range-2', 'robots-4', 'colors-1']
      - type:  ['without-chirality', 'range-2', 'robots-5', 'colors-1']
    impossibility:
      - type:  ['transparent', 'with-chirality', 'range-*', 'robots-2', 'colors-*']
      - type:  ['transparent', 'with-chirality', 'range-*', 'robots-3', 'colors-1']
  FUN2022:
    pdf: https://drops.dagstuhl.de/storage/00lipics/lipics-vol226-fun2022/LIPIcs.FUN.2022.7/LIPIcs.FUN.2022.7.pdf
    type: ['3d', 'finite-grid', 'with-chirality', 'opaque', 'modifiable-colors']
    algos: 
      - type: ['range-1', 'robots-3', 'colors-5']
      - type: ['range-2', 'transparent', 'robots-5', 'colors-1']
    impossibility:
      - type: ['transparent', 'range-1', 'robots-2', 'colors-*']

  Netys2021:
    pdf: https://univoak.eu/islandora/object/islandora:136781/datastream/PDF/download/citation.pdf
    type: ['2d', 'finite-grid', 'without-chirality', 'opaque', 'modifiable-colors']
    algos: 
      - type: ['range-1', 'robots-3', 'colors-3']
      - type: ['range-2', 'transparent', 'robots-5', 'colors-1']
    impossibility:
      - type: ['transparent', 'range-*', 'robots-2', 'colors-*']

  TCS2023-2: #previously ICDCN2021
    pdf: https://uca.hal.science/hal-03130277/document
    type: ['2d', 'finite-grid', 'with-chirality', 'opaque', 'modifiable-colors']
    algos: 
      - type: ['range-1', 'robots-2', 'colors-3']
      - type: ['range-2', 'robots-2', 'colors-2']
      - type: ['range-2', 'robots-3', 'colors-1']
      - type: ['async', 'range-2', 'robots-2', 'colors-3']
    impossibility:
      - type: ['transparent', 'range-*', 'robots-1', colors-*]
      - type: ['transparent', 'range-*', 'robots-2', colors-1]
      - type: ['transparent', 'range-1', 'robots-2', colors-2]
      - type: ['transparent', 'range-1', 'robots-3', colors-1]

  FUN2021:
    pdf: https://drops.dagstuhl.de/storage/00lipics/lipics-vol157-fun2021/LIPIcs.FUN.2021.6/LIPIcs.FUN.2021.6.pdf
    type: ['2d', 'infinite-grid', 'without-chirality', 'opaque', 'modifiable-colors']
    algos:
      - type: ['range-1', 'robots-6', 'colors-3']
      - type: ['range-2', 'robots-8', 'colors-1']
    impossibility:
      - type: ['transparent', 'topology-*','range-1', 'robots-*', 'colors-1']


  Netys2020:
    pdf: https://arxiv.org/pdf/1905.09271.pdf
    type: ['2d', 'infinite-grid', 'with-chirality', 'opaque']
    algos:
      - type: ['range-1', 'robots-5', 'colors-5', 'modifiable-colors']
      - type: ['range-1', 'robots-6', 'colors-3', 'fixed-colors']
        notes: 'not edge-exclusive'
      - type: ['range-2', 'robots-7', 'colors-1', 'modifiable-colors']
    impossibility:
      - type: ['transparent', 'range-*', 'robots-4', 'colors-*', 'modifiable-colors']
  
  SSS2024:
    algos:
      - type: ['async', '2d', 'finite-grid', 'with-chirality', 'opaque', 'range-2', 'robots-4', 'colors-1', 'modifiable-colors'] 
      - type: ['async', '2d', 'finite-grid', 'without-chirality', 'transparent', 'range-2', 'robots-3', 'colors-2', 'modifiable-colors'] 
      - type: ['async', '2d', 'finite-grid', 'without-chirality', 'opaque', 'range-3', 'robots-3', 'colors-1', 'modifiable-colors'] 
    impossibility:
      - type: ['async', '2d', 'topology-*', 'with-chirality', 'transparent', 'range-1', 'robots-*', 'colors-*', 'modifiable-colors']

  unpublished:
    algos:
      - type: ['2d', 'infinite-grid', 'without-chirality', 'opaque', 'range-1', 'robots-20', 'colors-5', 'fixed-colors']
        notes: 'not edge-exclusive'
      - type: ['2d', 'infinite-grid', 'with-chirality', 'opaque', 'range-1', 'robots-7', 'colors-15', 'modifiable-colors']
        notes: 'Perpetual'
      - type: ['2d', 'infinite-grid', 'without-chirality', 'opaque', 'range-1', 'robots-5', 'colors-12', 'modifiable-colors']
        notes: 'Perpetual'
      - type: ['2d', 'infinite-grid', 'without-chirality', 'opaque', 'range-1', 'robots-6', 'colors-2', 'modifiable-colors']

      - type: ['async', '2d', 'infinite-grid', 'with-chirality', 'opaque', 'range-2', 'robots-6', 'colors-3', 'fixed-colors']
      - type: ['async', '2d', 'infinite-grid', 'with-chirality', 'opaque', 'range-3', 'robots-9', 'colors-1']



other:
  - title: "Non-exclusive"
    description: "Non exlusive, so robots can form towers"
    citation: '"Terminating Grid Exploration with Myopic Luminous Robots" by Shota Nagahama, Fukuhito Ooshita, and Michiko Inoue. 2021'
    paper: https://arxiv.org/pdf/2102.06006.pdf
    type: ['2d', 'finite-grid', 'transparent', 'modifiable-colors']
    algos:
      - type: ['fsync', 'range-2', 'colors-2', 'with-chirality','robots-2']
      - type: ['fsync', 'range-2', 'colors-2', 'without-chirality','robots-3']
      - type: ['fsync', 'range-2', 'colors-1', 'with-chirality','robots-3']
      - type: ['fsync', 'range-2', 'colors-1', 'without-chirality','robots-4']

      - type: ['fsync', 'range-1', 'colors-3', 'with-chirality','robots-2']
      - type: ['fsync', 'range-1', 'colors-3', 'without-chirality','robots-4']
      - type: ['fsync', 'range-1', 'colors-2', 'with-chirality','robots-3']
      - type: ['fsync', 'range-1', 'colors-2', 'without-chirality','robots-5']

      - type: ['async', 'range-2', 'colors-3', 'with-chirality','robots-2']
      - type: ['async', 'range-2', 'colors-3', 'without-chirality','robots-3']
      - type: ['async', 'range-2', 'colors-2', 'with-chirality','robots-3']
      - type: ['async', 'range-2', 'colors-2', 'without-chirality','robots-4']

      - type: ['async', 'range-1', 'colors-3', 'with-chirality','robots-3']
      - type: ['async', 'range-1', 'colors-3', 'without-chirality','robots-6']

  - title: Ants
    description: Ants can see themselves when on the same node (there is no notion of visibility range). They move on a persistantly oriented grid.
    citation: '"Tight Bounds for Deterministic High-Dimensional Grid Exploration" by Sebastian Brandt, Julian Portmann, Jara Uitto. 2020'
    type: ['42d', 'infinite-grid', 'modifiable-colors']
    paper: https://arxiv.org/pdf/2005.12623.pdf
    impossibility:
      - type: ['2d', 'fsync', 'robots-2']
      - type: ['2d', 'ssync', 'robots-3']
    algos:
      - type: ['fsync', 'robots-3']
      - type: ['ssync', 'robots-4']

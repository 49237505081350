

export const onError = (errorMsg) => {
    const el = document.getElementById('error-log');

    if(errorMsg === null) {
        el.style.display = 'none';
        return;
    }
    el.style.display = 'block';
    console.log(errorMsg)
    el.innerHTML = '<pre>'+errorMsg+'</pre>';

}
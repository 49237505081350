import { Config } from "./config.ts";
import { Position } from "./position.ts";
import { Options } from "./types.ts";



export default function (content : string, options: Options) 
{
  if(content.trim() === '') {
    content = '.\n';
  }

  const intialConfigurations: Record<string, { 
    config: Config,
    gridSize: number[],
    seed: number }> = {}

  const initialLines = content.split('\n');
  initialLines.push('__empty:[0,0,0],0');
  
  let currentConfigName = 'default';
  let currentConfigSize = options.walls ? options.walls[1].slice() : [6,6,6];
  let currentConfigLines :string[] = []
  let currentConfigSeed = 0;
  let currentConfigType = 'finite-grid';
  for(const line of initialLines)
  {
    let m = line.replaceAll(' ','').match(/^([a-zA-Zx0-9\-_]+):([^[]*)\[(\d+),(\d+),(\d+)\],(-?\d+)/);
    if(m) {

      const config = new Config({
        type: currentConfigType as 'torus' | 'infinite-grid' |'finite-grid',
        x: currentConfigSize[0],
        y: currentConfigSize[1],
        z: currentConfigSize[2],
        dimension: options.dimension
      });
      let z = 0
      let y = 0
      let empty = true;
      currentConfigLines.forEach((l) => {
        if(l.trim() == '') {
          z++;
          y = 0
          return;
        }
        empty = false
        l.split('').forEach((color, x) => {
          if(color == '.') return;
          if(color == ' ') return;
          config.set(new Position(x, currentConfigSize[1]-y-1, z), color)
        })
        y++;
      })
      if(!empty)
      {
        intialConfigurations[currentConfigName] = {
          config: config,
          gridSize: currentConfigSize,
          seed: currentConfigSeed
        }
      }
      currentConfigLines = []
      currentConfigName = m[1];
      currentConfigType = m[2] || 'finite-grid';
      if(!['finite-grid', 'infinite-grid', 'torus'].includes(currentConfigType)) {
        throw new Error('the configuration type '+currentConfigType+' is not supported. It must be finite-grid, infinite-grid or torus');
      }
      currentConfigSize = [parseInt(m[3]), parseInt(m[4]), parseInt(m[5])];
      currentConfigSeed = parseInt(m[6]);
      continue;
    }
    else if(line.replaceAll(' ','').match(/^([a-zA-Zx0-9\-_]+):\[(\d+),(\d+),(-?\d+)\]/)) {
      m = line.replaceAll(' ','').match(/^([a-zA-Zx0-9\-_]+):\[(\d+),(\d+),(-?\d+)\]/)
      throw new Error('please add ",SEED" at the end of the configuration '+(m && m[1]))
    }
    else if(line.replaceAll(' ','').match(/^([a-zA-Zx0-9\-_]+):/)) {
      m = line.replaceAll(' ','').match(/^([a-zA-Zx0-9\-_]+):/)
      throw new Error('please add "[x,y,z],SEED" at the end of the configuration '+(m && m[1]))
    }
    currentConfigLines.push(line);
  }
  return intialConfigurations;
}
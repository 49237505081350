

export function debounce(func, wait:number, context = {}) {
    let timeout: undefined | number;
    return function() {
        const ctx = context || this, args = arguments;
        const later = function() {
            timeout = undefined;
            func.apply(ctx, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}


import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Position } from './position.ts';

import { 
  Algo, prettyView,
 } from './types.ts';

import Stats from 'three/examples/jsm/libs/stats.module'
import { GUI } from 'dat.gui'
import { appendLog, logAppendView } from './gui_editors_panel.ts';
import { Config } from './config.ts';
import createRobotMesh from './robotMesh.ts';


import { GUI } from 'dat.gui'
import {debounce} from './utils.ts'

let requestInit = () => {};
let requestUpdate = () => {};

const guiOptions = {
  nodeOpacity: 0.1,
  arrowOpacity: 0.7
}
const gui = new GUI();//{ autoPlace: false });
gui.domElement.id = 'gui';
const sceneGui = gui.addFolder('Scene');
sceneGui.add(guiOptions, 'nodeOpacity', 0, 1).onChange(function() { requestInit(); });
sceneGui.add(guiOptions, 'arrowOpacity', 0, 1).onChange(function() { requestUpdate(); });
gui.close();


let scene = null;
let camera = null;
let gridHelper = null;
let clock = null;
let clockDeltaThrottle = 0;
let mixer = null;
let robotMesh = [];
let nodeMarker = {};
let nodeMarkerList = [];
let algo: Algo | null = null;
let currentConfig: Config | null = null;
let threeContainer = null;

export const sceneOptions = {
  printMarker: false
}

function getCanvasDimensions() {
  return [threeContainer.offsetWidth, threeContainer.offsetHeight];
}
let renderer = null;

export function getRenderer() {
  return renderer;
}

export function create(container, _algo) {

    algo = _algo;
    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xf0f0f0 );

    clock = new THREE.Clock();
    mixer = new THREE.AnimationMixer();


    const dirLight1 = new THREE.DirectionalLight( 0xffffff );
    dirLight1.position.set( 0.9, 0.7, 1 );
    dirLight1.target.position.set(0,0, 0);
    dirLight1.castShadow = true;
    dirLight1.shadow.mapSize.width = 1024;
    dirLight1.shadow.mapSize.height = 1024;    
    scene.add( dirLight1 );

    const dirLight2 = new THREE.DirectionalLight( 0x999999 );
    dirLight2.position.set( - 0.6, - 0.6, - 1 );
    scene.add( dirLight2 );   

    const ambientLight = new THREE.AmbientLight( 0x999999 );
    scene.add( ambientLight );

    //var shadowHelper = new THREE.CameraHelper( dirLight1.shadow.camera );
    //scene.add( shadowHelper );

    const planeGeometry = new THREE.PlaneGeometry( 2000, 2000 );
    planeGeometry.rotateX( - Math.PI / 2 );
    const planeMaterial = new THREE.ShadowMaterial( { opacity: 1 } );

    const plane = new THREE.Mesh( planeGeometry, planeMaterial );
    plane.position.y = -1;
    plane.receiveShadow = true;
    scene.add( plane );

    gridHelper = [];


    renderer = new THREE.WebGLRenderer( { antialias: true } );
    //renderer.shadowMap.enabled = true;
    threeContainer = document.getElementById('three-container');
    threeContainer.appendChild( renderer.domElement );
    window.addEventListener( 'resize', onWindowResize, false );

    setupOnClickEvent();

    const frustumSize = 100;
    const [W, H] = getCanvasDimensions();
    const aspect =  W / H;
    //camera = new THREE.PerspectiveCamera( 75, aspect, 0.1, 1000 );
    camera = new THREE.OrthographicCamera( 
    frustumSize * aspect / - 2, 
    frustumSize * aspect / 2, 
    frustumSize / 2, 
    frustumSize / - 2, 1, 1000 );

    //camera.position.set( 200, 150, 300 );
    camera.position.set( -200, 150, 200 );
    camera.zoom = 0.7;
    

    function onWindowResize(){
        const [W, H] = getCanvasDimensions();
        renderer.setSize( W, H );

        const aspect = W / H
        camera.aspect = aspect;  
        
        camera.left = frustumSize * aspect / - 2;
        camera.right = frustumSize * aspect / 2;
        camera.top = frustumSize / 2;
        camera.bottom = - frustumSize / 2;
    
        camera.updateProjectionMatrix();
    }
    onWindowResize();

    // Controls
    const controls = new OrbitControls( camera, renderer.domElement );
    controls.damping = 0.2;
    controls.target.x = 50;
    controls.target.y = 25;
    controls.target.z = 50;
    controls.addEventListener( 'change', render );
    controls.update();

    setTimeout(() => {
      if(algo.options.dimension === 2) 
      {

        const currentConfigSize = algo.options.walls[1].slice();

        camera.position.set(currentConfigSize[0]*10/2-1, 150, currentConfigSize[1]*10/2);
        camera.rotation.y += Math.PI/2;
        camera.zoom = 0.3;

        controls.target.x = currentConfigSize[0]*10/2;
        controls.target.y = 25;
        controls.target.z = currentConfigSize[1]*10/2;

        controls.update();
      }
    }, 400)



    const stats = Stats()
    document.body.appendChild(stats.dom)
    stats.dom.style.top='auto';
    stats.dom.style.bottom='0';
    sceneGui.add(stats.dom.style, 'display').options({visible:'block', hidden: 'none'}).name('Stats').setValue('none');


    
    function animate() {
        requestAnimationFrame( animate );
        controls.update();
        camera.updateProjectionMatrix();
        stats.update();
        render();
    }

    function render() {
      if (mixer) {
        let delta = clock.getDelta();
        clockDeltaThrottle += delta;
        if(clockDeltaThrottle >= 1) 
        {
          clockDeltaThrottle -= 1;
          if(clockDeltaThrottle > 0.5) clockDeltaThrottle = 0.5;
          mixer.update(1);
        }
      }
        renderer.render( scene, camera );
    }

    render();
    animate();
}


function setupOnClickEvent() {
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  threeContainer.addEventListener('click', onClick, false );
  function onClick( event ) {
    
    event.stopPropagation();
    let [w,h] = getCanvasDimensions()
    mouse.x = ( event.clientX / w ) * 2 - 1;
    mouse.y = - ( event.clientY / h ) * 2 + 1;

    // update the picking ray with the camera and mouse position
    raycaster.setFromCamera( mouse, camera );

    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects( scene.children );

    for ( let i = 0; i < intersects.length; i ++ ) {
      if(intersects[ i ].object.robotPos) {
        let pos = intersects[ i ].object.robotPos;
        logAppendView(currentConfig.getView(pos, algo.options.visibilityRange, algo.options.dimension))
        console.log(prettyView(currentConfig.getView(pos, algo.options.visibilityRange, algo.options.dimension), 3, {dest: 'idle'}))
        break;
      }

    }

  }
}



let cubeGrid = []
//TODO: Move the grid (now positions starts at 0,0,0)

export function init(_currentConfig: Config, algo: Algo) {
  requestInit = debounce(() => init(_currentConfig, algo), 500);


  currentConfig = _currentConfig;
  if(gridHelper != null) {
    gridHelper.forEach(l => scene.remove(l));
    const currentConfigSize = algo.options.walls[1].slice();
    const material = new THREE.LineBasicMaterial( { color: 0x999999 } );
    //material.opacity = 0.25;
    //material.transparent = true;
    for(let x = 0; x < currentConfigSize[0]; x++) {
      const points = []
      points.push( new THREE.Vector3( 0, -5, x*10) );
      points.push( new THREE.Vector3( 10*(currentConfigSize[1]-1), -5, x*10 ) );
      const geometry = new THREE.BufferGeometry().setFromPoints( points );
      const line = new THREE.Line( geometry, material );
      scene.add( line );
      gridHelper.push(line);
    }
    for(let y = 0; y < currentConfigSize[1]; y++) {
      const points = []
      points.push( new THREE.Vector3( y*10, -5, 0 ) );
      points.push( new THREE.Vector3( y*10, -5, 10*(currentConfigSize[0]-1) ) );
      const geometry = new THREE.BufferGeometry().setFromPoints( points );
      const line = new THREE.Line( geometry, material );
      scene.add( line );
      gridHelper.push(line);
    }
    /*const maxConfigSize = Math.max(currentConfigSize[0],currentConfigSize[1]) - 2;
    gridHelper = new THREE.GridHelper( 10*maxConfigSize, maxConfigSize);
    gridHelper.position.y = 0;
    gridHelper.position.x = 10*(maxConfigSize+2)/2;
    gridHelper.position.z = 10*(maxConfigSize+2)/2;
    gridHelper.material.opacity = 0.25;
    gridHelper.material.transparent = true;*/
  }

  


  cubeGrid.forEach(c => scene.remove(c)); 
  cubeGrid = []

  if(algo.options.walls) {
    let [sx, sy, sz] = algo.options.walls[0];
    let [ex, ey, ez] = algo.options.walls[1];

    nodeMarkerList.forEach(c => scene.remove(c));
    
    nodeMarker = {};
    for(let y = sy ; y < ey; y++) {
      for(let x = sx ; x < ex; x++) {
        for(let z = sz ; z < ez; z++) {
          const geometry = new THREE.SphereGeometry( 1, 2, 2 );
          const material = new THREE.MeshBasicMaterial( {color: 0x000000} );
          const sphere = new THREE.Mesh( geometry, material );
          sphere.position.x = y*10;
          sphere.position.y = z*10;
          sphere.position.z = x*10;
          sphere.material.opacity = guiOptions.nodeOpacity
          sphere.material.transparent = true;
          cubeGrid.push(sphere);
          scene.add( sphere );
        }
      }
    }

    if(sceneOptions.printMarker) {
      for(let x = sx+1 ; x < ex; x++) {
        nodeMarker[x] = {}
        for(let y = sy+1 ; y < ey; y++) {
          nodeMarker[x][y] = {}
          for(let z = sz+1 ; z < ez; z++) {
            const geometry = new THREE.SphereGeometry( 0.3, 4, 4 );
            const material = new THREE.MeshBasicMaterial( {color: 0xFF0000} );
            const sphere = new THREE.Mesh( geometry, material );
            sphere.position.x = y*10;
            sphere.position.y = z*10;
            sphere.position.z = x*10;
            sphere.material.opacity = 1
            sphere.material.transparent = false;
            nodeMarker[x][y][z] = sphere;
            scene.add( sphere )
            nodeMarkerList.push(sphere);
          }
        }
      }
    }
  }

  updateRobotMesh(currentConfig, algo);
}


export function updateRobotMesh(_currentConfig : Config, algo: Algo) {

    requestUpdate = debounce(() => updateRobotMesh(_currentConfig, algo), 100);

    currentConfig = _currentConfig
    robotMesh.forEach(m => scene.remove(m));
    robotMesh = [];

    currentConfig.robots().forEach(r => {
        //if(r.color == 'W') return;
        
        let mesh = createRobotMesh(algo, _currentConfig, r, mixer, guiOptions.arrowOpacity)

        scene.add(mesh);
        robotMesh.push(mesh);

        if(sceneOptions.printMarker && nodeMarker[r.pos.x][r.pos.y][r.pos.z]) {
          scene.remove(nodeMarker[r.pos.x][r.pos.y][r.pos.z]);
          nodeMarker[r.pos.x][r.pos.y][r.pos.z] = null;
        }
    });
    if(currentConfig.topology.type === 'torus')
    {
      currentConfig.robots().forEach(r => {
        if(r.color == 'W') return;
        for(let [offx,offy] of [[1,0],[-1,0],[0,-1],[0,1],[1,1],[-1,-1],[1,-1],[-1,1]])
        { 
          const fake_r = {
            ...r,
            pos: new Position(
              (r.pos.x + offx*currentConfig.topology.x),
              (r.pos.y + offy*currentConfig.topology.y),
              r.pos.z,
            )
          }
          let mesh = createRobotMesh(algo, _currentConfig, fake_r, mixer, guiOptions.arrowOpacity, true);
          scene.add(mesh);
          robotMesh.push(mesh);
        }
    });
    }
    clockDeltaThrottle = 1; // this will update the rotation of the robots
}


/*
function onMouseDown(event) {
  event.preventDefault();
  var rightclick;
    if (!event) var event = window.event;
    if (event.which) rightclick = (event.which == 3);
    else if (event.button) rightclick = (event.button == 2);
  if (!rightclick) return;

  raycaster.setFromCamera(mouse, camera);

  var intersects = raycaster.intersectObjects([torusKnot]);

  if (intersects.length) {
    intersect = intersects[0].object;
    menu.style.left = (event.clientX - rect.left) + "px";
    menu.style.top = (event.clientY - rect.top) + "px";
    menu.style.display = "";
  }
  else{
    intersect = undefined;
  }
}*/


import { Algo, View, Direction, Destination, destinationIncludes, Rule, Rules, 
    prettyView, directionToPrettyString, NodeState, 
    directionEquals, getDirections, RuleReference, RulesReference, ruleToString, Options, Alias, toCanonical, prettyRule } from './types.ts';

export type DirTransformation = {
    f:(dir: Direction) => Direction, 
    inv:(dir: Direction) => Direction,
    name: string
};

export function applyTransformation(t: DirTransformation, rule: Rule) : Rule
{
    return {
        view: rule.view.apply(t.f),
        destination: {
            ...rule.destination,
            dir: rule.destination.dir.map(d => t.inv(d))
        },
        options: rule.options
    };
}

export function mirrorXYDir(dir: Direction) : Direction {
  return {x: dir.x, y: dir.y, z: -dir.z};
}
export function mirrorXYDirInverse(dir: Direction) : Direction {
  return {x: dir.x, y: dir.y, z: -dir.z};
}
export const mirrorXY: DirTransformation = {
    f:mirrorXYDir, inv: mirrorXYDirInverse, name:'Mirror XY'
};
  
export function mirrorXYRule({view, destination, options}: Rule) : Rule
{
    return {
        view:view.apply(mirrorXYDirInverse),
        destination: {
            ...destination,
            dir:destination.dir.map(d => mirrorXYDir(d))
        },
        options
    };
}
export function mirrorXZDir(dir: Direction) : Direction {
    return {x: dir.x, y: -dir.y, z: dir.z};
}
export function mirrorXZDirInverse(dir: Direction) : Direction {
    return {x: dir.x, y: -dir.y, z: dir.z};
}
export const mirrorXZ: DirTransformation = {
    f:mirrorXZDir, inv: mirrorXZDirInverse, name:'Mirror XZ'
}
    
export function mirrorXZRule({view, destination, options}: Rule) : Rule
{
    return {
        view:view.apply(mirrorXZDirInverse),
        destination: {
            ...destination,
            dir:destination.dir.map(d => mirrorXZDir(d))
        },
        options
    };
}

export function rotateXDir(dir: Direction) : Direction {
    return {x: dir.x, y: dir.z, z: -dir.y};
}
export function rotateXDirInverse(dir: Direction) : Direction {
    return {x: dir.x, y: -dir.z, z: dir.y};
}
export const rotateX: DirTransformation = {
    f:rotateXDir, inv: rotateXDirInverse, name:'Rotate X'
}
  
export function rotateXRule({view, destination, options}: Rule) : Rule
{
    return {
        view:view.apply(rotateXDirInverse),
        destination: {
            ...destination,
            dir:destination.dir.map(d => rotateXDir(d))
        },
        options
    };
}
  
export function rotateYDir(dir: Direction) : Direction {
    return {x: -dir.z, y: dir.y, z: dir.x};
}

export function rotateYDirInverse(dir: Direction) : Direction {
    return {x: dir.z, y: dir.y, z: -dir.x};
}
export const rotateY: DirTransformation = {
    f:rotateYDir, inv: rotateYDirInverse, name:'Rotate Y'
}
  
export function rotateYRule({view, destination, options}: Rule) : Rule
{
    return {
        view:view.apply(rotateYDirInverse),
        destination: {
            ...destination,
            dir:destination.dir.map(d => rotateYDir(d))
        },
        options
    };
}
  
export function rotateZDir(dir: Direction) :Direction {
    return {x: dir.y, y: -dir.x, z: dir.z};
}

export function rotateZDirInverse(dir: Direction) :Direction {
    return {x: -dir.y, y: dir.x, z: dir.z};
}

export const rotateZ: DirTransformation = {
    f:rotateZDir, inv: rotateZDirInverse, name:'Rotate Z'
}
  
export function rotateZRule({view, destination, options}: Rule) : Rule
{
    return {
        view:view.apply(rotateZDirInverse),
        destination: {
            ...destination,
            dir:destination.dir.map(d => rotateZDir(d))
        },
        options
    };
}



function dirEqual(dir1: Direction[], dir2: Direction[]) : boolean {
    return dir1.filter(d => !dir2.filter(d2 => directionEquals(d,d2)).length).length === 0
        && dir2.filter(d => !dir1.filter(d1 => directionEquals(d,d1)).length).length === 0;
}
  
export function testRule(rules: Rules, {view, destination, options}: Rule) {
    let k = view.toString();
    return (
      !rules[k] 
      || options.override
      || (
        dirEqual(rules[k].destination.dir, destination.dir)
        && (rules[k].destination.color || view.getCenter()) == (destination.color || view.getCenter())
      )
    );
}
function resolveAlias<T>(key: NodeState, values: NodeState[], rule:Rule, f: (v:Rule) => T) {
    if(!rule.view.includes(key)){
      f(rule)
      return;
    }
    for(let v of values) {
      const new_view = rule.view.replaceOne(key, v);
      const new_dest = { ...rule.destination };
      if(new_view.getCenter() !== rule.view.getCenter())
      {
        new_dest.color = v;
      } 
      resolveAlias(key, values, {
        view: new_view,
        destination: new_dest,
        options: rule.options,
      }, f);
    }
}

function resolveWalls<T>(view:View, dimension:number,  f: (v:View) => T) {
    if(!view.hasWallsBoundaries()){
        view = addConsistentWalls(view, dimension);
        f(view);
        return;
    }
    const [v1, v2] = view.removeFirstWall(dimension);

    resolveWalls(v1, dimension, f);
    resolveWalls(v2, dimension, f);
}

function addConsistentWalls<T>(view: View, dimension: number) {
    let newView : View | null = null;
    const nv = () => newView === null ? (newView = view.copy()) : newView;

    const wallsBoundaries = [
        [-view.visibilityRange()-1,view.visibilityRange()+1],
        [-view.visibilityRange()-1,view.visibilityRange()+1],
        [-view.visibilityRange()-1,view.visibilityRange()+1],
    ];
    for(let d = 1; d <= view.visibilityRange(); d++) {
        if(view.get({x:-d, y:0, z:0}) === 'W' && wallsBoundaries[0][0] < d) {
            wallsBoundaries[0][0] = -d;
        }
        if(view.get({x:d, y:0, z:0}) === 'W' && wallsBoundaries[0][1] > d) {
            wallsBoundaries[0][1] = d;
        }
        if(view.get({x:0, y:-d, z:0}) === 'W' && wallsBoundaries[1][0] < d) {
            wallsBoundaries[1][0] = -d;
        }
        if(view.get({x:0, y:d, z:0}) === 'W' && wallsBoundaries[1][1] > d) {
            wallsBoundaries[1][1] = d;
        }
        if(view.get({x:0, y:0, z:-d}) === 'W' && wallsBoundaries[2][0] < d) {
            wallsBoundaries[2][0] = -d;
        }
        if(view.get({x:0, y:0, z:d}) === 'W' && wallsBoundaries[2][1] > d) {
            wallsBoundaries[2][1] = d;
        }
    }

    for(const dir of getDirections(view.visibilityRange()))
    {
        if(dir.z != 0 && dimension == 2)
        {
            nv().set(dir, '.'); 
            continue;
        }
        if( (dir.x === wallsBoundaries[0][0] || dir.x === wallsBoundaries[0][1])
            && dir.y >= wallsBoundaries[1][0] 
            && dir.y <= wallsBoundaries[1][1] 
            && dir.z >= wallsBoundaries[1][0] 
            && dir.z <= wallsBoundaries[1][1])
        {
            nv().set(dir, 'W'); 
        }
        if(dir.x < wallsBoundaries[0][0] || dir.x > wallsBoundaries[0][1])
        {
            nv().set(dir, '.'); 
        }
        if( (dir.y === wallsBoundaries[1][0] || dir.y === wallsBoundaries[1][1])
            && dir.x >= wallsBoundaries[0][0]
            && dir.x <= wallsBoundaries[0][1]
            && dir.z >= wallsBoundaries[2][0]
            && dir.z <= wallsBoundaries[2][1])
        {
            nv().set(dir, 'W');
        }
        if(dir.y < wallsBoundaries[1][0] || dir.y > wallsBoundaries[1][1])
        {
            nv().set(dir, '.'); 
        }
        if( (dir.z === wallsBoundaries[2][0] || dir.z === wallsBoundaries[2][1])
            && dir.x >= wallsBoundaries[0][0]
            && dir.x <= wallsBoundaries[0][1]
            && dir.y >= wallsBoundaries[1][0]
            && dir.y <= wallsBoundaries[1][1])
        {
            nv().set(dir, 'W');
        }
        if(dir.z < wallsBoundaries[2][0] || dir.z > wallsBoundaries[2][1])
        {
            nv().set(dir, '.'); 
        }
    }
    if(newView !== null) {
        return newView;
    }
    return view;
}

export function errorRuleOverlap({
    view:v1, destination:d1}: Rule, 
    {view:v2, destination:d2}: Rule, 
    ref1:RuleReference, 
    ref2:RuleReference,
    dimension: number) 
{
    throw `
    Overlaping rules:
${prettyView(v1, dimension)} -> ${d1.dir.map(d => directionToPrettyString(d)).join(', ')} ${d1.color}
    obtained from rule at line ${ref1?.line}${ref1?.transformations.length ? ', after ['+ref1?.transformations.join(', ')+']' : ''}
${prettyView(v2, dimension)} -> ${d2.dir.map(d => directionToPrettyString(d)).join(', ')} ${d2.color}
    obtained from rule at line ${ref2?.line}${ref2?.transformations.length ? ', after ['+ref2?.transformations.join(', ')+']' : ''}
        `;
}


type CachedRules = {[view:string]: {rules: Rules, rulesReference:RulesReference} };
let cachedRules: CachedRules = {};

function cacheRule(key: string, t: {rules: Rules, rulesReference:RulesReference})
{
    //cachedRules[key] = t;
}



const mergeDirections = (rule1:Rule, rule2:Rule) : Direction[] => {
    const dir = [...rule1.destination.dir];
    rule2.destination.dir.forEach(d => { if(dir.filter(d2 => directionEquals(d,d2)).length == 0) dir.push(d)});
    return dir;
}

function testAndAdd(rules: Rules, rulesReference: RulesReference, rule: Rule, ref: RuleReference, ignoreOverlap: boolean, options: Options) {
    const k = rule.view.toString();
    if(!ignoreOverlap && !testRule(rules, rule))
    {
        errorRuleOverlap(rule, rules[k], ref, rulesReference[k], options.dimension);
    }
    if(rules[k]) {
        if(ignoreOverlap) {
            rules[k].destination.dir = mergeDirections(rules[k], rule);
            return;
        }
    }
    rules[k] = rule;
    if(ref) rulesReference[k] = ref;
}

export function compose2(...f: ((r:Rule)=>Rule)[]) : (r:Rule)=>Rule {
    let a = [...f].reverse();
    return (x) => a.reduce((acc, f) => { 
        return f(acc);
    }, x);
}

export function compose(...ts: (DirTransformation)[]) : DirTransformation
{
    const a = [...ts].reverse();
    const b = [...ts];
    return {
        f: (x) =>
            a.reduce((acc, f) => { 
                return f.f(acc);
            }, x),
        inv: (x) => b.reduce((acc, f) => { 
            return f.inv(acc);
        }, x),
        name: b.map(t => t.name).join(', ')
    };
}
export function transformationsList(options: Options) : DirTransformation[] {

    const transformations: (DirTransformation)[] = []
    transformations.push({f:(x:Direction)=>x, inv:(x:Direction)=>x, name: 'identity'});
    transformations.push(rotateZ);
    transformations.push(compose(rotateZ, rotateZ));
    transformations.push(compose(rotateZ, rotateZ, rotateZ));
    if(options.dimension === 3) 
    {
        transformations.push(compose(rotateX, rotateX));
        transformations.push(compose(rotateX, rotateX, rotateZ));
        transformations.push(compose(rotateX, rotateX, rotateZ, rotateZ));
        transformations.push(compose(rotateX, rotateX, rotateZ, rotateZ, rotateZ));

        const otherSubgroup: (DirTransformation)[] = [];
        otherSubgroup.push(compose(rotateZ, rotateX));
        otherSubgroup.push(compose(rotateZ, rotateX,rotateZ, rotateX));
        
        for(const t2 of [...transformations]) {
            for(const t of otherSubgroup) {
                transformations.push(compose(t, t2));
            }
        }
    }
    if(options.chirality === false)
    {
        let mir: DirTransformation;
        if(options.dimension === 3)
            mir = mirrorXY;
        else
            mir = mirrorXZ; 

        for(const t2 of [...transformations]) {
            transformations.push(compose(mir, t2));
        }
    }

    return transformations;
}
/*
export function namedTransformationsList(options: Options) : [(r:Rule) => Rule, number, string][] {

    const transformations: [(r:Rule) => Rule, number, string][] = []
    if(options.dimension === 3) 
    {
        transformations.push([rotateX, 4, 'X rotation']);
        transformations.push([rotateY, 4, 'Y rotation']);
    }
    transformations.push([rotateZ, 4, 'Z rotation']);
    if(options.chirality === false)
    {
        if(options.dimension === 3)
            transformations.push([mirrorXY, 2, 'XY Mirroring']); //prettier in 3 dimentions
        else
            transformations.push([mirrorXZ, 2, 'X Mirroring']); 
    }

    return transformations;
}
*/

function _transformRule(
    rule: Rule, 
    ruleReference: RuleReference,
    alias: Alias,
    options: Options,
    {ignoreOverlap}: {ignoreOverlap:boolean} = {ignoreOverlap:false}
) : 
{ rules:Rules, rulesReference:RulesReference } 
{
    rule.view = addConsistentWalls(rule.view, options.dimension);
    const rules: Rules = {
        [rule.view.toString()]: rule
    };
    const rulesReference: RulesReference = {
        [rule.view.toString()]: ruleReference
    };
    
    if(rule.view.hasWallsBoundaries()) {
        Object.entries(rules).forEach(([k, rule]: [string, Rule]) => {
            let ref = rulesReference[k] && {
                ...rulesReference[k], 
                transformations: [
                    ...rulesReference[k].transformations,
                    'walls'
                ]
            }
            resolveWalls(rule.view, options.dimension, (view2) =>{
                testAndAdd(rules, rulesReference,
                    {view:view2,destination:rule.destination, options:rule.options},
                    ref, ignoreOverlap, options)
            })
        })
    }
    
    
    Object.entries(alias).forEach(([aliasKey, aliasValues]: [string, string[]]) => {
        Object.entries(rules).forEach(([k, rule]: [string, Rule]) => {
            let ref = {
                ...rulesReference[k], 
                transformations: [
                    ...rulesReference[k].transformations,
                    'alias '+aliasKey
                ]
            }
            resolveAlias(aliasKey, aliasValues, rule, (rule2)=>{
                testAndAdd(rules, rulesReference,
                    rule2,
                    ref,
                    ignoreOverlap, options)
            })
        })
    })

    const rulesCanonical: Rules = {
    };
    const rulesCanonicalReference: RulesReference = {
    };
    
    for(const k of Object.keys(rules))
    {
        const can = toCanonical(rules[k], options);
/*
        console.log('parsing ', prettyRule(rules[k]));
        console.log('1 ', prettyRule(can.rule));
        console.log('2 ', prettyRule(applyTransformation(can.transformations[0], rules[k])));
        console.log('3 ', prettyRule(applyTransformation(can.transformations[0], rules[k])));
        console.log('4 ', prettyRule(applyTransformation(can.transformation, rules[k])));
*/

        if(can.transformations.length > 1 
            && !can.transformations.every(
                t => applyTransformation(t, rules[k]).destination.dir.every(
                    d => (
                        destinationIncludes(can.rule.destination, d)
                    )
                ) // 
            )
        ) {
            can.transformations.forEach(
                t => applyTransformation(t, rules[k]).destination.dir.forEach(
                    d => {
                        if(!destinationIncludes(can.rule.destination, d)) {
                            throw `
    Ambiguous rule at line ${rulesReference[k]?.line}:
${prettyView(rules[k].view, options.dimension)} -> ${rules[k].destination.dir.map(d => directionToPrettyString(d)).join(', ')} ${rules[k].destination.color}
    
    The rule is symmetric so the destinations should be 
    the set of undistinguishable destinations.

    In particular, two symmetric views are
${prettyView(can.rule.view, options.dimension)}
obtained after ${can.transformation.name}
and
${prettyView(applyTransformation(t, rules[k]).view, options.dimension)}
obtained after ${t.name}
`;
                        }
                    }
                )
            );
            
        }
        const canonicalKey = can.rule.view.toString();
        rulesCanonical[canonicalKey] = can.rule;
        rulesCanonicalReference[canonicalKey] = rulesReference[k];
    }

/*
    const transformations = namedTransformationsList(options);

    transformations.forEach(([transformation, order, tr_name] : [(r:Rule) => Rule, number, string]) => {
        Object.entries(rules).forEach(([k, rule]: [string, Rule]) => {
            let ref = rulesReference[k];
            ref = ref && {
                ...ref, 
                transformations: [
                    ...ref.transformations,
                    tr_name
                ]
            }

            for(let i = 0; i < order-1; i++) {
                rule = transformation(rule);
                
                testAndAdd(rules, rulesReference,
                    rule,
                    ref,
                    ignoreOverlap, options)
            }
        })
    })*/
    return {rules: rulesCanonical, rulesReference: rulesCanonicalReference};
}


export function transformRules(algo : Algo, {ignoreOverlap}: {ignoreOverlap:boolean} = {ignoreOverlap:false}) 
{
    const {options, alias} = algo;
    try{ 
        const rawRules = algo.rules;
        algo.rules = {};
        const rawRulesReference = algo.rulesReference;
        algo.rulesReference = {};
        Object.entries(rawRules).forEach(([key, rule]) => {
            let t = _transformRule(rule, rawRulesReference[key], alias, options, {ignoreOverlap});
            
            for(const k in t.rules) {
                testAndAdd(algo.rules, algo.rulesReference,
                    t.rules[k],
                    t.rulesReference[k],
                    ignoreOverlap, options);
            }
        })
        //console.log('rules:', Object.keys(rules).length);
        //console.log('rules:', Object.keys(cachedRules));
    } catch(error) {
      cachedRules = {}
      console.log(error)
      throw error;
    }
}

export default {};

/*
export enum Direction {
    IDLE,
    FRONT,
    LEFT,
    BACK,
    RIGHT,
    ABOVE,
    BELOW
}*/

import { Config } from "./config.ts";
import { Position } from "./position.ts";
import random from "./random.ts";
import { applyTransformation, DirTransformation, transformationsList } from "./symmetries.ts";


export interface Direction {
    x: number;
    y: number;
    z: number;
}


export interface Robot {
    pos: Position, 
    color: string
}

export const IDLE = {x:0,y:0,z:0},
    FRONT    = {x:0,y:1,z:0},
    BACK  = {x:0,y:-1,z:0},
    LEFT  = {x:-1,y:0,z:0},
    RIGHT = {x:1,y:0,z:0},
    ABOVE = {x:0,y:0,z:1},
    BELOW = {x:0,y:0,z:-1};


export const directionToString = function({x,y,z}: Direction) {
    return `${x},${y},${z}`
}
export const directionEquals = function(d1: Direction, d2: Direction) {
    return d1.x === d2.x && d1.y === d2.y && d1.z === d2.z;
}
export const directionToPrettyString = function(dir: Direction) {
    return {
        [directionToString(IDLE)]: 'idle',
        [directionToString(FRONT)]: 'front',
        [directionToString(BACK)]: 'back',
        [directionToString(LEFT)]: 'left',
        [directionToString(RIGHT)]: 'right',
        [directionToString(ABOVE)]: 'above',
        [directionToString(BELOW)]: 'below',
    }[directionToString(dir)]
}

/*
export class Direction {
    x: number;
    y: number;
    z: number;
    constructor(x,y,z) {this.x = x; this.y = y; this.z = z};
    toString() { return  `${this.x},${this.y},${this.z}` };
    equals(dir: Direction) {
        return dir.x === this.x && dir.y === this.y && dir.z === this.z;
    }
};
*/

export function getDirections(visibilityRange: number) {
    let s : Direction[] = [];
    for(let z = visibilityRange; z >= -visibilityRange; z--) {
        for(let y = visibilityRange; y >= -visibilityRange; y--) {
            for(let x = -visibilityRange; x <= visibilityRange; x++) {
                if(Math.abs(x)+Math.abs(y)+Math.abs(z) <= visibilityRange)
                {
                    s.push({x,y,z});
                }
            }
        }
    }
    return s;
}

export type NodeState = string;

type ViewStates = {
    [key: string]: NodeState;
};

export type WallBoundaries = [number, number];
export type WallsBoundaries = {wallX: WallBoundaries, wallY: WallBoundaries, wallZ: WallBoundaries};

// Number of state in a view
// https://oeis.org/A001845
// formula a(n) = (2*n+1)*(2*n^2 + 2*n + 3)/3
const StateCount = {
    7: 1, 
    25 : 2,
    63: 3,
    129: 4,
    231: 5,
    377: 6,
    575: 7,
    833: 8,
    1159: 9
}

export class View {
    states: ViewStates;
    wallsBoundaries: WallsBoundaries
    visibleCount: number;

    constructor() {
        this.states = {}
        this.wallsBoundaries = {wallX:[0,0], wallY:[0,0], wallZ:[0,0]}
        this.visibleCount = 0;
    }
    set(dir: Direction, ns: NodeState) {
        this.states[directionToString(dir)] = ns;
        if(ns != '.') this.visibleCount++;
    }
    get(dir: Direction) {
        return this.states[directionToString(dir)];
    }
    getCenter() {
        return this.get(IDLE);
    }
    visibilityRange() {
        /*switch(Object.keys(this.states).length) {
            case 5: return 1;
            case 7: return 1;
            case 25: return 2;
            case 63: return 3;
        }
        throw `View with ${Object.keys(this.states).length} states is not implemented`;
        */
        const vr = StateCount[Object.keys(this.states).length as keyof typeof StateCount];
        if(!vr) throw `View with ${Object.keys(this.states).length} states is not implemented`;
        return vr;
    }
    setWalls({wallX, wallY, wallZ}: WallsBoundaries) {
        this.wallsBoundaries = {wallX, wallY, wallZ}
    }
    hasWallsBoundaries() {
        return this.wallsBoundaries.wallX[0] != 0
        || this.wallsBoundaries.wallX[1] != 0
        || this.wallsBoundaries.wallY[0] != 0
        || this.wallsBoundaries.wallY[1] != 0
        || this.wallsBoundaries.wallZ[0] != 0
        || this.wallsBoundaries.wallZ[1] != 0;
    }
    removeFirstWall(dimension: number) {
        const wallsType: [keyof WallsBoundaries, keyof Direction][] = [['wallX', 'x'], ['wallY', 'y'], ['wallZ', 'z']];
        
        for(const [wallType, vec] of wallsType)
        {
            for(let i = 0; i < 2; i++) 
            {
                if(this.wallsBoundaries[wallType][i]) {
                    const nvW = this.copy();
                    const nv = this.copy();
                    nvW.wallsBoundaries[wallType][i] = 0;
                    nv.wallsBoundaries[wallType][i] += nv.wallsBoundaries[wallType][i] > 0 ? 1 : -1;
                    if(Math.abs(nv.wallsBoundaries[wallType][i]) > this.visibilityRange()){
                        nv.wallsBoundaries[wallType][i] = 0;
                    }
                    for(const dir of getDirections(this.visibilityRange()))
                    {
                        if(    (dir.z === 0 || dimension > 2)
                            && dir[vec] === this.wallsBoundaries[wallType][i]) {
                            const k = directionToString(dir);
                            nv.states[k] = '.';
                            nvW.states[k] = 'W';
                        }
                    }
                    return [nv, nvW];
                }
            }
        }
        throw 'You should call removeFirstWall only if there are walls to remove (use hasWallsBoundaries)';
    }
    toString() { 
        const s = []
        for(const {x,y,z} of getDirections(this.visibilityRange()))
        {
            s.push(this.get({x,y,z}));
        }
        return s.join('')+(this.hasWallsBoundaries() ? JSON.stringify(this.wallsBoundaries) : '');
    }
    includes(s: NodeState) {
        return Object.values(this.states).includes(s);
    }
    replaceOne(s: NodeState, ns: NodeState) {
        const nv = new View();
        let found = false;
        for(const k in this.states) {
            if(!found && this.states[k] === s)
            {
                nv.states[k] = ns;
                found = true;
            }
            else {
                nv.states[k] = this.states[k]
            }
        }
        return nv
    }

    copy() {
        const nv = new View();
        nv.visibleCount = this.visibleCount;
        for(const k in this.states) {
            nv.states[k] = this.states[k]
        }
        nv.wallsBoundaries = {
            wallX: [this.wallsBoundaries.wallX[0], this.wallsBoundaries.wallX[1]],
            wallY: [this.wallsBoundaries.wallY[0], this.wallsBoundaries.wallY[1]],
            wallZ: [this.wallsBoundaries.wallZ[0], this.wallsBoundaries.wallZ[1]],
        }
        return nv
    }

    apply(f: (dir:Direction) => Direction) {
        const nv = new View();
        for(const k of getDirections(this.visibilityRange())) {
            nv.states[directionToString(k)] = this.states[directionToString(f(k))]
        }
        return nv;
    }


    static emptyView(vr: number, center: NodeState = '.') {
        const v = new View();
        for(const dir of getDirections(vr)) {
            v.set(dir, '.');
        }
        v.set(IDLE, center);
        return v;
    }
};

export type Destination = {
    dir: Direction[],
    color: string
}
export type RuleOptions = {
    maxWalls: number | undefined,
    override: boolean | undefined,
}

export type Rule = {
    view: View, 
    destination: Destination,
    options: RuleOptions
}
export type RuleReference = {transformations:string[], line: number, line_end:number, view:View}

export type Rules = {[view:string]: Rule}
export type RulesReference = {
    [key:string]: RuleReference
}
export type Alias = {
    [key:string]: string[]
}

export type Options = {
    walls: null | [number[], number[]],
    colors: {[key:string]: number},
    chirality: boolean,
    visibilityRange: number,
    is3d: boolean,
    cacheRules: boolean,
    seed: number
    dimension: 2 | 3
}
export function optionsCopy(o : Options) : Options {
    return o;
}

export type Algo = {
    alias: Alias,
    rules: Rules,
    rulesReference: RulesReference,
    options: Options,
    usedViews: Set<number>,
}


export function destinationIncludes(dest: Destination, dir: Direction) : boolean
{
    for(const d of dest.dir) {
        if(directionEquals(d, dir)) return true;
    }
    return false;
}

const toCanonicalCache = new Map<string, Map<string, DirTransformation[]>>();
export function toCanonical(rule: Rule, options: Options) 
    : {rule: Rule, transformation: DirTransformation, transformations: DirTransformation[]} 
{  
    const modelKey = options.dimension.toString() + '-' + options.chirality.toString();
    const key = rule.view.toString();
    if(!toCanonicalCache.has(modelKey)) {
        toCanonicalCache.set(modelKey, new Map<string, DirTransformation[]>());
    }
    const cachedTransformation = toCanonicalCache.get(modelKey)?.get(key);
    if(cachedTransformation) {
        //console.log('cached');
        return {rule: applyTransformation(cachedTransformation[0], rule), transformation: cachedTransformation[0], transformations: cachedTransformation};
    }

    const transformations = transformationsList(options);
    
    // find the transformations that minimizes the component
    let min = rule.view.toString();
    let minRule : Rule = rule;
    let minTransformations: (DirTransformation)[] = [];
    for(const transformation of transformations) {
        const transformedRule = applyTransformation(transformation, rule);

        const transformedComponent = transformedRule.view.toString();
        if(transformedComponent < min) {
            min = transformedComponent;
            minTransformations = [transformation];
            minRule = transformedRule;
        }
        else if(transformedComponent === min) {
            minTransformations.push(transformation);
        }
    }
    toCanonicalCache.get(modelKey)?.set(key, minTransformations);

    return {rule: minRule, transformation: minTransformations[0], transformations: minTransformations};
}


export const ruleToString = function(r: Rule) {
    return `${r.view.toString()} (${JSON.stringify(r.view.wallsBoundaries)})->[${r.destination.dir.map(d => directionToString(d)).join(',')}],${r.destination.color}`;
}

export function prettyRule(v : Rule, dimension = 3) {
    return prettyView(v.view, dimension, {dest: v.destination.dir.map(directionToPrettyString).join(',') + ', ' + v.destination.color} );
}
export function prettyView(v : View, dimension = 3, {dest} = {dest:''}) {
    let s = []
    let visibilityRange = v.visibilityRange();
    for(let z = visibilityRange; z >= -visibilityRange; z--) {
        if(dimension == 2 && z != 0) continue;

        for(let y = visibilityRange - Math.abs(z); y >= Math.abs(z) - visibilityRange; y--) {
            for(let x = -visibilityRange; x <= visibilityRange; x++) {
                if(Math.abs(x)+Math.abs(y)+Math.abs(z) <= visibilityRange)
                {
                    s.push(v.get({x,y,z}));
                }
                else
                {
                    s.push(' ');
                }
            }
            if(dest !== '' && y === 0 && z === 0) {
                s.push(' -> '+dest);
            }
            if(s[s.length-1] !== '\n') s.push('\n');
        }
    }
    return s.join('');
}

function randomInt(max:number, rng: () => number):number {
    return Math.floor(rng() * max);
}
function randomEl<T>(array:T[], rng: () => number):T {
    return array[randomInt(array.length, rng)];
}

export function getRobotDirections(algo: Algo, config: Config, pos: Position) {

    const view = config.getView(
        pos, 
        algo.options.visibilityRange,
        algo.options.dimension
    );

    const {rule, transformation} = toCanonical({
        view, 
        destination: {dir: [IDLE], color: 'A'},
        options: {maxWalls: undefined, override: undefined}
    }, algo.options);

    const key = rule.view.toString();
    
    if(algo.rules[key]) {
       return  algo.rules[key].destination.dir.map(d => transformation.f(d));
    }
    return [IDLE];
}

export function algoStep(algo: Algo, config: Config) {
    const nextConfig = new Config(config.topology);

    const rng = config.seed === -1 ? random(Math.floor(Math.random()*1000000)) : random(config.seed);
    for(const robot of config.robots()) {
        if(robot.color === 'W') {
            if(nextConfig.get(robot.pos) != '.') {
                throw 'Collision';
            }
            nextConfig.set(robot.pos, 'W');
            continue;
        };

        const view = config.getView(
                robot.pos, 
                algo.options.visibilityRange,
                algo.options.dimension
            );

        const {rule, transformation} = toCanonical({
            view, 
            destination: {dir: [IDLE], color: robot.color},
            options: {maxWalls: undefined, override: undefined}
        }, algo.options);

        //console.log(robot.pos, rule.view.toString())
        //console.log(prettyView(rule.view, algo.options.dimension));

        const key = rule.view.toString();

        let nextPos = robot.pos;
        let nextColor = robot.color;

        if(algo.rules[key]) {
            if(algo.rulesReference[key]) {
                algo.usedViews.add(algo.rulesReference[key].line);
            }
            const {dir, color=robot.color} = algo.rules[key].destination
            nextPos = robot.pos.to(transformation.f(randomEl(dir, rng.next)));
            nextColor = color;
        }
        if(nextConfig.get(nextPos) != '.') {
            throw 'Collision';
        }
        nextConfig.set(nextPos, nextColor);
    }
    nextConfig.seed = config.seed === -1 ? -1 : rng.seed();
    return nextConfig;
}


import * as THREE from 'three';

import { 
  IDLE, 
  FRONT,
  LEFT,
  BACK,
  RIGHT,
  ABOVE,
  BELOW,
  directionEquals,
  Direction,
  Algo, prettyView, getRobotDirections } from './types.ts';


function rot2quat(rot_dir: Direction, rot_angle: number) : number[] {
    let s = Math.sin(rot_angle/2);

    return [
        Math.cos(rot_angle/2),
        rot_dir.x * s,
        rot_dir.y * s,
        rot_dir.z * s
    ];
}

function dir2quat(d: Direction) {
    let rot_angle = 0;
    let rot_dir = d;
    if(directionEquals(d, BACK)) {
        rot_angle = Math.PI;
    } else if(directionEquals(d, LEFT)) {
        rot_angle = -Math.PI/2;
        rot_dir = FRONT;
    } else if(directionEquals(d, RIGHT)) {
        rot_angle = Math.PI/2;
        rot_dir = FRONT;
    } else if(directionEquals(d, ABOVE)) {
        rot_angle = -Math.PI/2;
        rot_dir = LEFT;
    } else if(directionEquals(d, BELOW)) {
        rot_angle = Math.PI/2;
        rot_dir = LEFT;
    }
    return rot2quat(rot_dir, rot_angle)
}

export default function(algo: Algo, _currentConfig: Config, r: Robot, mixer, arrowOpacity:number, fake: boolean = false) {

    const geometry = new THREE.BoxGeometry( 8, 8, 8 ); 
    let materials;
    let dir;
    if(!fake) {
        dir = getRobotDirections(algo, _currentConfig, r.pos) 
        if(directionEquals(dir[0], IDLE)) {
            materials = new THREE.MeshPhongMaterial( { 
            color: algo.options.colors[r.color] || 0x555555, flatShading: true 
            });
        } 
        else {
            materials = [];
            for(let i = 0; i < 6; i++) {
                const material = new THREE.MeshPhongMaterial();
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                ctx.fillStyle = "#"+((algo.options.colors[r.color] || 0x555555).toString(16).padStart(6,'0')); 
                ctx.fillRect(0, 0, 300, 300); 

                ctx.globalAlpha = arrowOpacity;
                if(i == 2 || i == 3 || i == 4) {
                    ctx.strokeStyle = "#000000";
                    ctx.lineWidth = 10;
                    ctx.beginPath();
                    ctx.moveTo(0,75);
                    ctx.lineTo(300,75);
                    ctx.moveTo(300,75);
                    ctx.lineTo(200,20);
                    ctx.moveTo(300,75);
                    ctx.lineTo(200,130);
                    ctx.stroke();
                } else
                if(i == 5) {
                    ctx.strokeStyle = "#000000";
                    ctx.lineWidth = 10;
                    ctx.beginPath();
                    ctx.moveTo(300,75);
                    ctx.lineTo(0,75);
                    ctx.moveTo(0,75);
                    ctx.lineTo(100,20);
                    ctx.moveTo(0,75);
                    ctx.lineTo(100,130);
                    ctx.stroke();
                } else
                if(i == 0) {
                    ctx.strokeStyle = "#000000";
                    ctx.lineWidth = 10;
                    ctx.beginPath();
                    ctx.moveTo(300/4,150/4);
                    ctx.lineTo(3*300/4,3*150/4);
                    ctx.moveTo(3*300/4,150/4);
                    ctx.lineTo(300/4,3*150/4);
                    ctx.stroke();
                } else
                if(i == 1) {
                    ctx.fillStyle = "#000000";
                    ctx.beginPath();
                    ctx.arc(150, 75, 20, 0, 2 * Math.PI);
                    ctx.fill();
                }
                material.map = new THREE.CanvasTexture( canvas );
                materials.push(material);
            }
        }
    } else {
        materials = new THREE.MeshPhongMaterial( { 
            color: algo.options.colors[r.color] || 0x555555
        });
        materials.transparent = true;
        materials.opacity = 0.35;
    }
    

    let mesh = new THREE.Mesh( geometry, materials );
    mesh.position.x = r.pos.y*10;
    mesh.position.y = r.pos.z*10;
    mesh.position.z = r.pos.x*10;
    mesh.castShadow = true;
    mesh.robotPos = r.pos;
    
    /*

        if(directionEquals(dir[0], BACK)) {
            mesh.rotateY(Math.PI);
        } else if(directionEquals(dir[0], LEFT)) {
            mesh.rotateY(Math.PI/2);
        } else if(directionEquals(dir[0], RIGHT)) {
            mesh.rotateY(-Math.PI/2);
        } else if(directionEquals(dir[0], ABOVE)) {
            mesh.rotateZ(Math.PI/2);
        } else if(directionEquals(dir[0], BELOW)) {
            mesh.rotateZ(-Math.PI/2);
        }
        */
    if(!fake) {
        let timing: number[] = [];
        let quaterions : number[] = [];
        for(let d of dir) {
            timing.push(timing.length);
            quaterions = quaterions.concat(dir2quat(d));
        }
        timing.push(timing.length);
        quaterions = quaterions.concat(dir2quat(dir[0]));

        let kf = new THREE.QuaternionKeyframeTrack( '.quaternion', timing, quaterions);
        
        const clip = new THREE.AnimationClip( 'Action', -1, [kf] );
        mixer.clipAction( clip, mesh ).play();
    }
    return mesh;
}